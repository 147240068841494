import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import(`@/layouts/index.vue`),
        children: [
            {
                path: "/",
                name: "Home",
                component: () => import(`@/views/Home/index.vue`),
            },
            {
                path: "/second",
                name: "Second",
                component: () => import(`@/views/Second/index.vue`),
            },
            {
                path: "/third/:type",
                name: "Third",
                component: () => import(`@/views/Third/index.vue`),
            },
        ],
    },
];

const createRouter = () => {
    return new VueRouter({
        routes,
    });
};

const router = createRouter();

export default router;
